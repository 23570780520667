import { IconButton } from "@radix-ui/themes";
import { setSwapStep, SwapStep } from "@/app/features/swap";
import BackIcon from "@/common/icons/BackIcon";
import RefreshIcon from "@/common/icons/RefreshIcon";
import React from "react";
import { useAppDispatch } from "@/app/hooks";
import { TokenList } from "@/app/features/swap/components/TokenList";

export default function CustomListPage({ isLoading = false }) {
  const dispatch = useAppDispatch();
  const setPage = (swapStep: SwapStep) => () => dispatch(setSwapStep(swapStep));

  return (
    <div className="flex flex-col gap-3">
      <div className="flex w-full items-center justify-between pb-1 text-xl">
        <IconButton
          variant="ghost"
          size="1"
          color="gray"
          onClick={setPage(SwapStep.SWAP)}
        >
          <BackIcon />
        </IconButton>
        <span className="text-lg font-semibold">Custom Tokens</span>
        <IconButton variant="ghost" size="1" color="gray" className="opacity-0">
          <RefreshIcon />
        </IconButton>
      </div>
      <TokenList isLoading={isLoading} customOnly />
    </div>
  );
}
