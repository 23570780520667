"use client";
import { Flex, Text } from "@radix-ui/themes";
import clsx from "clsx";
import {
  setInputAmount,
  useInputToken,
  useOutputToken,
} from "@/app/features/swap/slice";
import { useCallback, useMemo } from "react";
import { useAppDispatch } from "@/app/hooks";

import { Balance, Input, Price } from "./components";

export default function TokenInput({ isOutput = false, isloading = false }) {
  const inputToken = useInputToken();
  const outputToken = useOutputToken();
  const token = useMemo(
    () => (isOutput ? outputToken : inputToken),
    [isOutput, inputToken, outputToken],
  );

  const dispatch = useAppDispatch();

  const onAmountChange = useCallback(
    (val: string) => {
      const payload = { amount: val };
      dispatch(setInputAmount(payload));
    },
    [dispatch],
  );

  return (
    <Flex
      className={clsx("gap-2 rounded-xl px-4 py-3", {
        "bg-grayA-2": !isOutput,
        "shadow-[0_0_0_1px_color-mix(in_oklab,_var(--gray-a5),_var(--gray-5)_25%)]":
          isOutput,
      })}
      direction="column"
    >
      <Flex justify="between" align="center" className="min-h-6 w-full">
        <Text className="text-grayA-11" size="2" weight="medium">
          {!isOutput ? "Input" : "Output"}
        </Text>

        {token && (
          <Balance
            token={token}
            isLoading={isloading}
            isOutput={isOutput}
            onAmountChange={onAmountChange}
          />
        )}
      </Flex>
      <Input
        token={token}
        onAmountChange={onAmountChange}
        isLoading={isloading}
        isOutput={isOutput}
      />
      <Flex justify="between" align="center" className="w-full">
        <Text className="text-grayA-11" size="2" weight="medium">
          {isloading ? (
            <div className="flex h-5 items-center">
              <span className="flex h-4 w-20 animate-pulse rounded bg-gray-4"></span>
            </div>
          ) : (
            token?.name || ""
          )}
        </Text>

        <Price isOutput={isOutput} isLoading={isloading} />
      </Flex>
    </Flex>
  );
}
