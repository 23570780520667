"use client";

import { useEffect, useState } from "react";
import { useCopyToClipboard } from "usehooks-ts";
import { CheckIcon, CopyIcon } from "@radix-ui/react-icons";

interface CopyButtonProps {
  content: string;
}

export default function CopyButton({ content }: CopyButtonProps) {
  const [hasCopied, setHasCopied] = useState(false);
  const [, copyFn] = useCopyToClipboard();

  useEffect(() => {
    if (hasCopied) setTimeout(() => setHasCopied(false), 1500);
  }, [hasCopied]);

  const copyDetails = () => copyFn(content).then(() => setHasCopied(true));

  return (
    <div
      className="flex h-5 w-5 cursor-pointer items-center justify-center text-grayA-11"
      onClick={copyDetails}
      role="button"
    >
      {!hasCopied ? <CopyIcon /> : <CheckIcon />}
    </div>
  );
}
