import { Button, IconButton, TextField } from "@radix-ui/themes";
import { setSlippage, setSwapStep, SwapStep } from "@/app/features/swap";
import BackIcon from "@/common/icons/BackIcon";
import React from "react";
import { useAppDispatch } from "@/app/hooks";
import { resetSettings, useSlippage } from "@/app/features/swap/slice";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import clsx from "clsx";

export default function SettingsPage() {
  const dispatch = useAppDispatch();
  const slippage = useSlippage();
  const setPage = (swapStep: SwapStep) => () => dispatch(setSwapStep(swapStep));

  return (
    <div className="flex flex-col gap-3">
      <div className="flex w-full items-center justify-between pb-1 text-xl">
        <IconButton
          variant="ghost"
          size="1"
          color="gray"
          onClick={setPage(SwapStep.SWAP)}
        >
          <BackIcon />
        </IconButton>
        <span className="text-lg font-semibold">Settings</span>
        <Button
          variant="soft"
          size="1"
          onClick={() => dispatch(resetSettings())}
        >
          Reset
        </Button>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex items-center justify-between">
          <span className="text-lg font-semibold">Slippage</span>
          <span
            className={clsx(
              "flex items-center gap-1 font-medium text-grayA-11",
              {
                "text-yellowA-11": slippage > 1,
              },
            )}
          >
            {slippage > 1 && <ExclamationTriangleIcon />}
            <span>{slippage}%</span>
          </span>
        </div>

        <div className="flex gap-2">
          <Button
            variant={slippage === 0.1 ? "solid" : "soft"}
            className="flex-1"
            onClick={() => dispatch(setSlippage(0.1))}
          >
            0.1%
          </Button>
          <Button
            variant={slippage === 0.5 ? "solid" : "soft"}
            className="flex-1"
            onClick={() => dispatch(setSlippage(0.5))}
          >
            0.5%
          </Button>
          <Button
            variant={slippage === 1 ? "solid" : "soft"}
            className="flex-1"
            onClick={() => dispatch(setSlippage(1))}
          >
            1%
          </Button>
          <TextField.Input
            type="number"
            onChange={(e) => dispatch(setSlippage(e.target.valueAsNumber))}
            placeholder="Custom"
            className="min-w-auto w-auto flex-1 [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
          />
        </div>
      </div>
    </div>
  );
}
