import { Avatar, Button, Flex, Text } from "@radix-ui/themes";
import { setSwapStep, SwapStep } from "@/app/features/swap";
import clsx from "clsx";
import DownIcon from "@/common/icons/DownIcon";
import { IMaskInput } from "react-imask";
import { SerializedTokenType } from "@/app/features/swap/slice";
import { useAppDispatch } from "@/app/hooks";
import { useQuote } from "@/app/features/swap/hooks";
import { useMemo, useRef } from "react";

interface InputProps {
  isOutput?: boolean;
  isLoading?: boolean;
  token?: SerializedTokenType;
  onAmountChange?: (val: string) => void;
  disabled?: boolean;
  valueOverride?: string;
}
export default function Input({
  isLoading,
  token,
  onAmountChange,
  isOutput,
  disabled,
  valueOverride,
}: InputProps) {
  const dispatch = useAppDispatch();
  const setPage = (swapStep: SwapStep) => () => dispatch(setSwapStep(swapStep));

  const { inputAmount, outputAmount } = useQuote(isLoading);
  const amount = useMemo(
    () => valueOverride || (isOutput ? outputAmount : inputAmount),
    [valueOverride, isOutput, outputAmount, inputAmount],
  );

  const ref = useRef(null);
  const inputRef = useRef(null);

  return (
    <Flex justify="between" align="center" className="overflow-hidden">
      <Button
        variant={token ? "ghost" : "soft"}
        color={token || isLoading ? "gray" : "green"}
        className="py-1.5 pl-2"
        disabled={isLoading}
        onClick={
          disabled
            ? undefined
            : setPage(
                isOutput
                  ? SwapStep.SELECT_OUTPUT_TOKEN
                  : SwapStep.SELECT_INPUT_TOKEN,
              )
        }
      >
        {token && (
          <Avatar
            fallback={token.symbol[0]}
            src={token.logoURI}
            size="1"
            radius="full"
          />
        )}
        <Text
          as="span"
          className={clsx("font-semibold", {
            "text-gray-12": token,
          })}
          size="4"
          ml="2"
          mr="1"
        >
          {token ? token.symbol : "Select a token"}
        </Text>
        {!disabled && <DownIcon />}
      </Button>
      <IMaskInput
        className="overflow-hidden text-ellipsis bg-transparent text-right text-2xl outline-none"
        aria-invalid="false"
        tabIndex={0}
        autoComplete="off"
        inputRef={inputRef}
        maxLength={30}
        inputMode="decimal"
        placeholder="0.00"
        disabled={isLoading || isOutput || disabled}
        mask={Number}
        value={Number(amount) > 0 ? amount : ""}
        onAccept={(value, _, input) => {
          if (input) {
            onAmountChange?.(value);
          }
        }}
        onBlur={(e) => {
          e.currentTarget.scrollLeft = 0;
        }}
        overwrite
        radix="."
        ref={ref}
        scale={8} // access to nested input
        thousandsSeparator=","
        unmask={true}
      />
    </Flex>
  );
}
