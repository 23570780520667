import {
  useGetBalancesQuery,
  useGetPriceQuery,
  useGetQuoteQuery,
  useGetSwapQuery,
} from "@/app/services/1inch";
import { Address, erc20Abi, formatUnits, parseUnits, zeroAddress } from "viem";
import {
  useInputAmount,
  useInputToken,
  useOutputToken,
  useSlippage,
} from "@/app/features/swap/slice";
import {
  useAccount,
  useBalance,
  useBlockNumber,
  useChainId,
  useEstimateFeesPerGas,
  useFeeData,
  useReadContract,
} from "wagmi";
import { useDebounce } from "usehooks-ts";
import { useMemo } from "react";
import {
  NATIVES,
  ONEINCH_NATIVE_ADDRESS,
  ONEINCH_ROUTER_ADDRESS,
} from "@/app/features/swap/constants";
import {
  useGetSortedTokensQuery,
  useGetTokensQuery,
} from "@/app/services/tokens";
import { useCustomTokens } from "@/app/features/swap/persistedSlice";

export function usePrice(isLoading = false) {
  const inputToken = useInputToken();
  const outputToken = useOutputToken();
  const chainId = useChainId();
  const { data: block } = useBlockNumber();
  const native = useMemo(() => NATIVES[chainId || 1], [chainId]);

  return useGetPriceQuery(
    {
      chainId,
      addresses:
        inputToken && outputToken
          ? [inputToken.address, outputToken.address, native.address]
          : [native.address],
      block: "0",
    },
    {
      skip: !Boolean(inputToken && outputToken && !isLoading && block),
      selectFromResult: ({ data, ...rest }) => ({
        ...rest,
        outputPrice: data?.[outputToken?.address || zeroAddress]?.price,
        inputPrice: data?.[inputToken?.address || zeroAddress]?.price,
        nativePrice: data?.[native.address].price,
      }),
      refetchOnMountOrArgChange: true,
    },
  );
}

export function usePriceTotals(amount: bigint, isLoading = false) {
  const inputToken = useInputToken();
  const outputToken = useOutputToken();

  const inputAmount = useInputAmount();

  const { inputPrice, outputPrice, isFetching, nativePrice } =
    usePrice(isLoading);

  const outputAmount = useMemo(
    () =>
      (inputAmount &&
        Number(inputAmount) > 0 &&
        formatUnits(amount, outputToken?.decimals || 18)) ||
      undefined,
    [inputAmount, amount, outputToken?.decimals],
  );

  const inputTotalPrice = useMemo(
    () => Number(inputPrice) * Number(inputAmount) || 0,
    [inputPrice, inputAmount],
  );

  const outputTotalPrice = useMemo(
    () =>
      (inputAmount &&
        Number(inputAmount) > 0 &&
        Number(outputPrice) * Number(outputAmount)) ||
      0,
    [inputAmount, outputPrice, outputAmount],
  );

  const priceImpact = useMemo(() => {
    if (!outputAmount || Number(outputAmount) <= 0) return 0;
    const priceChange = inputTotalPrice - outputTotalPrice;
    return (priceChange / inputTotalPrice) * 100;
  }, [inputTotalPrice, outputAmount, outputTotalPrice]);

  return useMemo(
    () => ({
      isLoading: isLoading || isFetching,
      inputPrice,
      outputPrice,
      inputTotalPrice,
      outputTotalPrice,
      outputAmount,
      nativePrice,
      outputAmountBn: parseUnits(
        outputAmount || "0",
        outputToken?.decimals || 18,
      ),
      inputAmount,
      inputAmountBn: parseUnits(inputAmount || "0", inputToken?.decimals || 18),
      priceImpact,
    }),
    [
      inputAmount,
      inputPrice,
      inputToken?.decimals,
      inputTotalPrice,
      isFetching,
      isLoading,
      outputAmount,
      outputPrice,
      outputToken?.decimals,
      outputTotalPrice,
      priceImpact,
      nativePrice,
    ],
  );
}

export function useQuote(isLoading = false) {
  const { data: block } = useBlockNumber();
  const inputToken = useInputToken();
  const outputToken = useOutputToken();
  const chainId = useChainId();

  const inputAmount = useInputAmount();
  const debouncedAmount = useDebounce(inputAmount, 500);

  const {
    isFetching: isQuoteLoading,
    data: quote,
    refetch,
    error,
  } = useGetQuoteQuery(
    {
      chainId,
      src: inputToken?.address || zeroAddress,
      dst: outputToken?.address || zeroAddress,
      amount: parseUnits(
        debouncedAmount || "0",
        inputToken?.decimals || 18,
      ).toString(),
    },
    {
      skip: !Boolean(
        inputToken &&
          outputToken &&
          !isLoading &&
          block &&
          debouncedAmount &&
          Number(debouncedAmount) > 0,
      ),
      pollingInterval: 30000,
    },
  );

  const { isLoading: pricesLoading, ...prices } = usePriceTotals(
    BigInt(quote?.toAmount || 0),
    isLoading,
  );

  return useMemo(
    () => ({
      ...prices,
      quoteError: error as any,
      isLoading: isLoading || isQuoteLoading,
      pricesLoading,
      inputAmount,
      quote,
      refetch: refetch,
    }),
    [
      prices,
      error,
      isLoading,
      pricesLoading,
      isQuoteLoading,
      inputAmount,
      quote,
      refetch,
    ],
  );
}

export function useSwap(isLoading = false) {
  const inputToken = useInputToken();
  const outputToken = useOutputToken();
  const chainId = useChainId();
  const { data: block } = useBlockNumber();
  const slippage = useSlippage();
  const inputAmount = useInputAmount();
  const debouncedAmount = useDebounce(inputAmount, 500);
  const { address } = useAccount();
  const {
    isFetching: isSwapFetching,
    isLoading: isSwapLoading,
    data: swap,
    refetch,
    isUninitialized,
    error,
  } = useGetSwapQuery(
    {
      chainId,
      src: inputToken?.address || zeroAddress,
      dst: outputToken?.address || zeroAddress,
      amount: parseUnits(
        debouncedAmount || "0",
        inputToken?.decimals || 18,
      ).toString(),
      slippage,
      address: address || zeroAddress,
    },
    {
      skip: !Boolean(
        inputToken && outputToken && !isLoading && block && debouncedAmount,
      ),
      pollingInterval: 30000,
    },
  );

  const { isLoading: pricesLoading, ...prices } = usePriceTotals(
    BigInt(swap?.tx.minReceiveAmount || 0),
    isLoading,
  );

  return useMemo(
    () => ({
      ...prices,
      isLoading:
        isLoading ||
        pricesLoading ||
        isSwapLoading ||
        isUninitialized ||
        isSwapFetching,
      inputAmount,
      swap,
      error: error as any,
      refetch: refetch,
    }),
    [
      prices,
      isLoading,
      pricesLoading,
      isSwapLoading,
      isUninitialized,
      isSwapFetching,
      inputAmount,
      swap,
      error,
      refetch,
    ],
  );
}

export function useGasPrice(gas = 0) {
  const { data: fee } = useEstimateFeesPerGas();
  return useMemo(
    () => (fee?.maxFeePerGas || 0n) * BigInt(gas || 190386),
    [fee?.maxFeePerGas, gas],
  );
}

export function useCanSwap(inputAmountBn: bigint, gas = 0, isLoading = false) {
  const inputToken = useInputToken();
  const { address } = useAccount();
  const chainId = useChainId();
  const { data: allowance, refetch } = useReadContract({
    address: inputToken?.address || zeroAddress,
    abi: erc20Abi,
    functionName: "allowance",
    args: [address || zeroAddress, ONEINCH_ROUTER_ADDRESS[chainId || 1]],
    query: {
      enabled:
        !!inputToken?.address &&
        !!address &&
        inputToken.address !== ONEINCH_NATIVE_ADDRESS,
      refetchInterval: 10000,
    } as const,
  });

  const { data: balance } = useBalance({
    address,
    token:
      inputToken?.address !== ONEINCH_NATIVE_ADDRESS
        ? inputToken?.address
        : undefined,
    chainId,
    query: {
      refetchInterval: 10000,
    },
  });

  const { data: ethBalance } = useBalance({
    address,
    token:
      inputToken?.address !== ONEINCH_NATIVE_ADDRESS
        ? inputToken?.address
        : undefined,
    query: {
      refetchInterval: 10000,
    },
    chainId,
  });

  const gasPrice = useGasPrice(gas);

  const gasReserves = useMemo(
    () =>
      (ethBalance?.value || 0n) -
        (inputToken?.address === ONEINCH_NATIVE_ADDRESS ? inputAmountBn : 0n) ||
      0n,
    [ethBalance?.value, inputAmountBn, inputToken?.address],
  );
  const isApproved = useMemo(
    () => typeof allowance === "undefined" || allowance >= inputAmountBn,
    [allowance, inputAmountBn],
  );

  const hasBalance = useMemo(
    () => typeof balance === "undefined" || balance.value >= inputAmountBn,
    [balance, inputAmountBn],
  );

  const hasGasReserves = useMemo(
    () => gasReserves >= gasPrice,
    [gasReserves, gasPrice],
  );

  return useMemo(
    () => ({ isApproved, gasPrice, hasGasReserves, hasBalance, refetch }),
    [gasPrice, hasBalance, hasGasReserves, isApproved, refetch],
  );
}

export function useQuoteCanSwap(isLoading = false) {
  const { inputAmountBn, quote } = useQuote(isLoading);
  return useCanSwap(inputAmountBn, Number(quote?.gas || 0), isLoading);
}

export function useSwapCanSwap(isLoading = false) {
  const { inputAmountBn, swap } = useSwap(isLoading);
  return useCanSwap(inputAmountBn, Number(swap?.gas || 0), isLoading);
}

export function useTokens({
  isLoading = false,
  customOnly = false,
  query = "",
}) {
  const { address } = useAccount();
  const chainId = useChainId();
  const customTokens = useCustomTokens(chainId);
  const {
    data: tokensData,
    isLoading: isTokensLoading,
    isUninitialized: isTokensUninitialized,
  } = useGetTokensQuery(
    {
      chainId,
    },
    { skip: isLoading, pollingInterval: 30000 },
  );
  //     MAX(swap_fee) AS swap_fee,
  //     MAX(token_purchased_price) AS token_purchased_price,
  //     MIN(log_index) AS log_index,
  // MAX(token_received_amount) / 0.99 * MAX(token_purchased_price) AS trade_value_usd,
  // MAX(token_received_amount) / 0.99 * MAX(token_purchased_price) * 0.01 AS trade_fee_usd,
  //     bonus,
  //     bonusX,
  //     bonus_start_time,
  //     bonus_end_time,
  //     bonus_symbol,
  //     bonus_priority
  const {
    data: balancesData,
    isLoading: isBalancesLoading,
    isUninitialized: isBalancesUninitialized,
  } = useGetBalancesQuery(
    {
      chainId,
      address: address || zeroAddress,
      tokens: [
        ...(tokensData?.tokens.map((token) => token.address) || []),
        ...customTokens.map((token) => token.address),
      ],
    },
    {
      skip: isLoading || isTokensLoading || !tokensData?.tokens.length,
      refetchOnMountOrArgChange: true,
      pollingInterval: 30000,
    },
  );

  const { data: sortedTokensData, isLoading: isSortedTokensLoading } =
    useGetSortedTokensQuery(
      {
        tokens: customOnly ? [] : tokensData?.tokens || [],
        customTokens,
        balances: balancesData || {},
        query,
      },
      {
        skip: isLoading || !tokensData?.tokens.length,
        refetchOnMountOrArgChange: true,
        pollingInterval: 30000,
      },
    );

  return useMemo(
    () => ({
      tokens: sortedTokensData?.tokens || tokensData?.tokens || [],
      isSortedTokensLoading,
      isTokensLoading: isTokensLoading || isTokensUninitialized,
      isBalancesLoading: isBalancesLoading || isBalancesUninitialized,
      balances:
        balancesData &&
        new Map<Address, { balance: string; price: string }>(
          Object.entries(balancesData) as [
            Address,
            { balance: string; price: string },
          ][],
        ),
    }),
    [
      balancesData,
      isBalancesLoading,
      isBalancesUninitialized,
      isSortedTokensLoading,
      isTokensLoading,
      isTokensUninitialized,
      sortedTokensData?.tokens,
      tokensData?.tokens,
    ],
  );
}
