import { SVGProps } from "react";

export default function GasIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M16.14 8.79l-.02.01c.23.27.43.59.58.92l.09.19c.71 1.69.21 3.64-1.1 4.86-1.19 1.09-2.85 1.38-4.39 1.18-1.46-.18-2.8-1.1-3.57-2.37-.23-.39-.43-.83-.53-1.28-.13-.37-.17-.73-.2-1.1-.09-1.6.55-3.3 1.76-4.3-.55 1.21-.42 2.72.39 3.77l.11.13c.14.12.31.16.47.09.15-.06.27-.21.27-.39l-.07-.22c-.88-2.32-.14-5.03 1.73-6.56.51-.42 1.14-.8 1.8-.97-.68 1.36-.46 3.14.63 4.21.46.47 1.01.78 1.49 1.22l.56.61m-2.28 4.64l-.01-.01c.45-.39.7-1.06.68-1.66l-.03-.32c-.2-1-1.07-1.33-1.63-2.06-.17-.22-.32-.5-.43-.78-.22.5-.24.97-.15 1.51.1.57.33 1.06.21 1.65-.16.65-.67 1.3-1.56 1.51.5.49 1.31.88 2.12.6.26-.07.59-.26.8-.44M11 18v1c0 .55.45 1 1 1s1-.45 1-1v-1h2v1c0 .55.45 1 1 1s1-.45 1-1v-1c1.11 0 3 .9 3 2v2H4v-2c0-1.1 1.9-2 3-2v1c0 .55.45 1 1 1s1-.45 1-1v-1h2z" />
    </svg>
  );
}
