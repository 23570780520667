import { Button, Dialog } from "@radix-ui/themes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import React from "react";

export default function ConfirmTsx({ label = "Approval", reset = () => {} }) {
  return (
    <Dialog.Root open>
      <Dialog.Content style={{ maxWidth: 450 }} className="z-50 text-center">
        <Dialog.Title>Confirm {label}</Dialog.Title>
        <div className="py-6 text-9xl text-greenA-11">
          <FontAwesomeIcon icon={faSpinner} spin />
        </div>
        <Dialog.Description size="2" mb="4">
          Please, sign order with your wallet.
        </Dialog.Description>

        <div className="mt-8 w-full">
          <Dialog.Close>
            <Button
              onClick={reset}
              tabIndex={-1}
              variant="soft"
              color="gray"
              className="swap-button w-full"
            >
              Cancel
            </Button>
          </Dialog.Close>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  );
}
