"use client";
import { useEffect, useMemo, useState } from "react";
import { useChainId } from "wagmi";
import { useDispatch } from "react-redux";
import {
  resetSwapState,
  SerializedTokenType,
  setInputToken,
  setOutputToken,
  SwapStep,
  useSwapStep,
} from "@/app/features/swap/slice";
import { DEFAULT_TOKEN, NATIVES } from "@/app/features/swap/constants";
import SwapPage from "@/app/features/swap/components/Widget/pages/swap";
import ConfirmSwap from "@/app/features/swap/components/Widget/pages/confirm";
import SelectPage from "@/app/features/swap/components/Widget/pages/select";
import CustomPage from "@/app/features/swap/components/Widget/pages/custom";
import CustomListPage from "@/app/features/swap/components/Widget/pages/customList";
import SettingsPage from "@/app/features/swap/components/Widget/pages/settings";
import { useSearchParams } from "next/navigation";
import tokenList from "@/app/tokenList.json";

const tokens = tokenList.tokens as SerializedTokenType[];
export default function Widget({ hideHeader = false }) {
  const chainId = useChainId();
  const dispatch = useDispatch();

  // const router = useRouter();
  // const pathname = usePathname();
  const searchParams = useSearchParams()!;

  const inputTokenSearch = useMemo(() => {
    const search = searchParams.get("inputToken");
    if (!search) return undefined;

    return tokens.find(
      (token) =>
        token.chainId === chainId &&
        token.address.toLowerCase() === search.toLowerCase(),
    );
  }, [chainId, searchParams]);

  const outputTokenSearch = useMemo(() => {
    const search = searchParams.get("outputToken");
    if (!search) return undefined;

    return tokens.find(
      (token) =>
        token.chainId === chainId &&
        token.address.toLowerCase() === search.toLowerCase(),
    );
  }, [chainId, searchParams]);

  // // Get a new searchParams string by merging the current
  // // searchParams with a provided key/value pair
  // const createQueryString = useCallback(
  //   (name: string, value: Address) => {
  //     const params = new URLSearchParams(searchParams);
  //     params.set(name, value);
  //
  //     return params.toString();
  //   },
  //   [searchParams],
  // );

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    dispatch(resetSwapState());
    if (NATIVES[chainId] || inputTokenSearch) {
      dispatch(setInputToken(inputTokenSearch || NATIVES[chainId]));
    }

    dispatch(setOutputToken(outputTokenSearch || DEFAULT_TOKEN[chainId]));
    setIsLoading(false);
  }, [chainId, dispatch, inputTokenSearch, outputTokenSearch]);

  const swapStep = useSwapStep();

  if (swapStep === SwapStep.SWAP) {
    return <SwapPage isLoading={isLoading} hideHeader={hideHeader} />;
  }

  if (
    swapStep === SwapStep.CUSTOM_INPUT_TOKEN ||
    swapStep === SwapStep.CUSTOM_OUTPUT_TOKEN
  ) {
    return <CustomPage />;
  }

  if (swapStep === SwapStep.CUSTOM_TOKENS) {
    return <CustomListPage isLoading={isLoading} />;
  }

  if (swapStep === SwapStep.SETTINGS) {
    return <SettingsPage />;
  }

  if (
    swapStep === SwapStep.SELECT_OUTPUT_TOKEN ||
    swapStep === SwapStep.SELECT_INPUT_TOKEN
  ) {
    return <SelectPage isLoading={isLoading} />;
  }

  return <ConfirmSwap isLoading={isLoading} />;
}
