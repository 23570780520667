import { notifyError, ToastType } from "@/app/features/toaster/slice";
import { AppDispatch } from "@/app/store";

export const onError =
  (func: string, dispatch: AppDispatch) => (error: any) => {
    const { code, details, message, name } = error.cause;
    const rejected = name === "UserRejectedRequestError";
    if (rejected) {
      notifyError({
        code,
        details,
        func,
        hash: "0x",
        message: message.split("\n")[0],
        type: rejected ? ToastType.REJECTED : ToastType.REVERTED,
        dispatch,
      });
    }
  };
