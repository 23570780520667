import { Text } from "@radix-ui/themes";
import { useQuote } from "@/app/features/swap/hooks";
import { useMemo } from "react";

export default function Price({ isOutput = false, isLoading = false }) {
  const { inputTotalPrice, outputTotalPrice, priceImpact, pricesLoading } =
    useQuote(isLoading);
  const price = useMemo(
    () => (isOutput ? outputTotalPrice : inputTotalPrice),
    [inputTotalPrice, isOutput, outputTotalPrice],
  );
  return (
    <Text className="text-grayA-11" size="2" weight="medium" as="span">
      {pricesLoading ? (
        <div className="flex h-5 items-center">
          <span className="flex h-4 w-16 animate-pulse rounded bg-gray-4"></span>
        </div>
      ) : price > 0 ? (
        `~$${price.toFixed(4) || "0.00"}${
          isOutput && priceImpact > 0
            ? ` (${
                inputTotalPrice > outputTotalPrice ? "-" : "+"
              }${priceImpact.toFixed(2)}%)`
            : ""
        }`
      ) : (
        "-"
      )}
    </Text>
  );
}
