import { SerializedTokenType } from "@/app/features/swap/slice";
import { Avatar, Flex, Text } from "@radix-ui/themes";
import React from "react";

interface TokenInfoProps {
  isLoading?: boolean;
  isOutput?: boolean;
  token: SerializedTokenType;
  amount?: string;
  price: number;
  priceImpact: number;
  inputTotalPrice: number;
  outputTotalPrice: number;
}
export default function TokenInfo({
  isLoading,
  isOutput,
  token,
  amount,
  price,
  priceImpact,
  inputTotalPrice,
  outputTotalPrice,
}: TokenInfoProps) {
  return (
    <Flex
      className="gap-2 rounded-xl px-4 py-3 shadow-[0_0_0_1px_color-mix(in_oklab,_var(--gray-a5),_var(--gray-5)_25%)]"
      direction="column"
    >
      <Flex justify="between" align="center" className="min-h-6 w-full">
        <Text className="text-grayA-11" size="2" weight="medium">
          {!isOutput ? "Input" : "Output"}
        </Text>
      </Flex>
      <Flex justify="between" align="center" gap="2">
        <div className="flex items-center">
          <Avatar
            fallback={token.symbol[0]}
            src={token.logoURI}
            size="1"
            radius="full"
          />
          <Text
            as="span"
            className="font-semibold text-gray-12"
            size="4"
            ml="2"
            mr="1"
          >
            {token.symbol}
          </Text>
        </div>
        <div className="overflow-hidden text-ellipsis whitespace-nowrap bg-transparent text-right text-2xl outline-none">
          {!isLoading ? (
            Number(amount) > 0 ? (
              amount
            ) : (
              ""
            )
          ) : (
            <div className="flex h-8 items-center">
              <span className="flex h-6 w-48 animate-pulse rounded bg-gray-4"></span>
            </div>
          )}
        </div>
      </Flex>
      <Flex justify="between" align="center" className="w-full">
        <Text className="text-grayA-11" size="2" weight="medium">
          {token.name}
        </Text>

        <Text className="text-grayA-11" size="2" weight="medium" as="span">
          {isLoading ? (
            <div className="flex h-5 items-center">
              <span className="flex h-4 w-16 animate-pulse rounded bg-gray-4"></span>
            </div>
          ) : price > 0 ? (
            `~$${price.toFixed(4) || "0.00"}${
              isOutput && priceImpact > 0
                ? ` (${
                    inputTotalPrice > outputTotalPrice ? "-" : "+"
                  }${priceImpact.toFixed(2)}%)`
                : ""
            }`
          ) : (
            "-"
          )}
        </Text>
      </Flex>
    </Flex>
  );
}
