import React, { useState, MouseEvent, useMemo } from "react";
import { useTokens } from "@/app/features/swap/hooks";
import { Avatar, Button, TextField } from "@radix-ui/themes";
import { Cross1Icon, MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { Row } from "@/app/features/swap/components/Row";
import { getAddress, isAddress } from "viem";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import {
  toggleFavouriteToken,
  useFavoriteTokens,
} from "@/app/features/swap/persistedSlice";
import { useChainId } from "wagmi";
import {
  SerializedTokenType,
  setInputToken,
  setOutputToken,
  setSwapStep,
  SwapStep,
  swapTokenDirection,
  useInputToken,
  useOutputToken,
  useSwapStep,
} from "@/app/features/swap/slice";
import { useAppDispatch } from "@/app/hooks";

export const LIST_ROW_HEIGHT = 60;

function FaveToken({ token }: { token: SerializedTokenType }) {
  const chainId = useChainId();
  const swapStep = useSwapStep();
  const dispatch = useAppDispatch();
  const favoriteTokens = useFavoriteTokens(chainId);
  const inputToken = useInputToken();
  const outputToken = useOutputToken();
  const isInputToken = useMemo(
    () => Boolean(token?.address && inputToken?.address === token.address),
    [token, inputToken],
  );
  const isOutputToken = useMemo(
    () => Boolean(token?.address && outputToken?.address === token.address),
    [token, outputToken],
  );
  const isFavorite = useMemo(
    () =>
      Boolean(token && favoriteTokens.find((t) => t.address === token.address)),
    [favoriteTokens, token],
  );

  const isSelected = useMemo(() => {
    return isInputToken || isOutputToken;
  }, [isInputToken, isOutputToken]);

  const removeToken = (
    e: MouseEvent<HTMLSpanElement>,
    token: SerializedTokenType,
  ) => {
    e.stopPropagation();
    dispatch(toggleFavouriteToken(token));
  };

  const selectToken = () => {
    if (
      isSelected &&
      ((swapStep === SwapStep.SELECT_INPUT_TOKEN && isOutputToken) ||
        (swapStep === SwapStep.SELECT_OUTPUT_TOKEN && isInputToken))
    ) {
      dispatch(swapTokenDirection());
      dispatch(setSwapStep(SwapStep.SWAP));
      return;
    }

    if (!token || isSelected) return;

    const stFunc =
      swapStep === SwapStep.SELECT_INPUT_TOKEN ? setInputToken : setOutputToken;
    dispatch(stFunc(token));

    dispatch(setSwapStep(SwapStep.SWAP));
  };

  if (
    (isInputToken && swapStep === SwapStep.SELECT_INPUT_TOKEN) ||
    (isOutputToken && swapStep === SwapStep.SELECT_OUTPUT_TOKEN)
  ) {
    return null;
  }

  return (
    <Button
      size="2"
      key={token.address}
      onClick={selectToken}
      variant="outline"
      color="gray"
      className="relative cursor-pointer hover:[--cross-opacity:1]"
    >
      <span
        onClick={(e) => removeToken(e, token)}
        className="absolute -right-1 -top-1 flex h-4 w-4 cursor-pointer items-center justify-center rounded-full bg-green-9 text-[#fff] opacity-[var(--cross-opacity,0)] transition-opacity"
      >
        <Cross1Icon width="12px" height="12px" />
      </span>
      <Avatar fallback={token.symbol} src={token.logoURI} size="1" />
      {token.symbol}
    </Button>
  );
}

export default function TokenList({
  isLoading = false,
  customOnly = false,
  step = 5,
}) {
  const chainId = useChainId();
  const [query, setQuery] = useState("");
  const favoriteTokens = useFavoriteTokens(chainId);
  const { tokens, balances, isTokensLoading, isBalancesLoading } = useTokens({
    isLoading,
    customOnly,
    query,
  });

  return (
    <div className="flex flex-col gap-4">
      <TextField.Root className="pl-2">
        <TextField.Slot className="text-greenA-8">
          <MagnifyingGlassIcon height="24" width="24" />
        </TextField.Slot>

        <TextField.Input
          size="3"
          className="h-12 pl-2"
          placeholder="Enter symbol, name or address"
          onChange={(e) => setQuery(e.currentTarget.value || "")}
        />
      </TextField.Root>
      {favoriteTokens.length > 0 && !customOnly && (
        <div className="flex flex-wrap gap-2">
          {favoriteTokens.map((token) => (
            <FaveToken token={token} key={token.address} />
          ))}
        </div>
      )}
      {(isTokensLoading || !tokens) && (
        <div
          className="overflow-hidden rounded-xl"
          style={{ height: LIST_ROW_HEIGHT * step }}
        >
          {Array.from({ length: step }).map((_, i) => (
            <Row key={i} />
          ))}
        </div>
      )}

      {!tokens.length && isAddress(query) && (
        <div
          className="overflow-hidden rounded-xl"
          style={{ height: LIST_ROW_HEIGHT * step }}
        >
          <Row token={query} customOnly={customOnly} />
        </div>
      )}
      {!isTokensLoading && tokens.length > 0 && (
        <div
          className="overflow-hidden rounded-xl"
          style={{ height: LIST_ROW_HEIGHT * step }}
        >
          <AutoSizer disableWidth>
            {({ height }) => (
              <FixedSizeList
                className="list"
                height={height}
                itemCount={tokens.length}
                itemData={tokens}
                itemSize={LIST_ROW_HEIGHT}
                width="100%"
              >
                {({ data, index, style }) => {
                  const token = data[index];
                  const balance = balances?.get(getAddress(token.address));
                  return (
                    <div style={style}>
                      <Row
                        customOnly={customOnly}
                        token={token}
                        key={token.name}
                        isBalanceLoading={isBalancesLoading}
                        isLoading={isTokensLoading}
                        balance={
                          Number(balance?.price || 0) *
                          Number(balance?.balance || 0)
                        }
                      />
                    </div>
                  );
                }}
              </FixedSizeList>
            )}
          </AutoSizer>
        </div>
      )}
    </div>
  );
}
