import { Button, Callout, Flex, IconButton, Text } from "@radix-ui/themes";
import RefreshIcon from "@/common/icons/RefreshIcon";
import { TokensIcon } from "@/common/icons/TokensIcon";
import SettingsIcon from "@/common/icons/SettingsIcon";
import {
  resetSwapState,
  setSwapStep,
  SwapStep,
  swapTokenDirection,
  useInputToken
} from "@/app/features/swap/slice";
import { useAppDispatch } from "@/app/hooks";
import { TokenInput } from "@/app/features/swap/components";
import { useQuote, useQuoteCanSwap } from "@/app/features/swap/hooks";
import ConfirmSwap from "@/app/features/swap/components/SwapInfo/SwapInfo";
import { ArrowDownIcon, InfoCircledIcon } from "@radix-ui/react-icons";
import React, { useEffect, useMemo, useState } from "react";
import {
  useAccount,
  useChainId,
  useSendTransaction,
  useWaitForTransactionReceipt,
} from "wagmi";
import ConfirmTsx from "@/app/features/swap/components/ConfirmTsx/ConfirmTsx";
import WaitTsx from "@/app/features/swap/components/WaitTsx/WaitTsx";
import ErrorTsx from "@/app/features/swap/components/ErrorTsx/ErrorTsx";
import { onError } from "@/app/features/toaster/utils";
import { notifySuccess } from "@/app/features/toaster";
import { useIsMounted } from "usehooks-ts";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import Link from "next/link";
import { useGetApprovalQuery } from "@/app/services/1inch";
import { Hex, zeroAddress } from "viem";

export const MAX_UINT256 = BigInt(
  "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff",
);
export default function SwapPage({ isLoading = false, hideHeader = false }) {
  const dispatch = useAppDispatch();
  const setPage = (swapStep: SwapStep) => () => dispatch(setSwapStep(swapStep));
  const { address } = useAccount();
  const { openConnectModal } = useConnectModal();
  const isMounted = useIsMounted()();
  const {
    refetch,
    inputAmountBn,
    priceImpact,
    isLoading: isQuoteLoading,
    quoteError,
  } = useQuote(isLoading);
  const inputToken = useInputToken();
  const chainId = useChainId();
const [curHash, setCurHash] = useState<Hex | null>(null);
  const {
    hasGasReserves,
    hasBalance,
    isApproved,
    refetch: refetchApproval,
  } = useQuoteCanSwap(isLoading);

  const { data: approvalTsx } = useGetApprovalQuery(
    {
      chainId,
      approveAmount: MAX_UINT256.toString(),
      tokenContractAddress: inputToken?.address || zeroAddress,
    },
    { skip: isApproved || !inputToken?.address },
  );

  const {
    isPending: isTsxPending,
    data,
    sendTransaction: write,
    reset,
  } = useSendTransaction();

  const {
    isLoading: isTsxWaiting,
    isError: isWaitingError,
    error,
    data: transaction,
  } = useWaitForTransactionReceipt({
    hash: data,
  });

  useEffect(() => {
    if(transaction && curHash !== transaction.transactionHash) {
    if(transaction?.status === "success") {
      refetchApproval().then(() => {
        notifySuccess({
          hash: transaction.transactionHash,
          func: "Approve",
          dispatch,
        });
      })
    }else if(transaction?.status === "reverted" || isWaitingError) {
      onError("Approve", dispatch)
    }
    setCurHash(transaction.transactionHash)
    }
  }, [curHash,dispatch, isWaitingError, refetchApproval, transaction])

  const params = useMemo(() => (approvalTsx?.data?.[0]
    ? {
      to: inputToken?.address || zeroAddress,
      data: approvalTsx.data[0].data,
      gasPrice: BigInt(approvalTsx.data[0].gasPrice),
      gas: BigInt(approvalTsx.data[0].gasLimit),
    }
    : {}), [approvalTsx, inputToken])

  return (
    <>
      {isTsxPending && <ConfirmTsx reset={reset} />}
      {isTsxWaiting && <WaitTsx isApproving hash={data} />}
      {isWaitingError && (
        <ErrorTsx
          isApproving
          hash={data}
          error={error}
          reset={reset}
          retry={() => write?.(params)}
        />
      )}

      <Flex direction="column" gap="3">
        <Flex
          align="center"
          justify={hideHeader ? "end" : "between"}
          gap="6"
          px="2"
        >
          {!hideHeader && (
            <Flex align="center" gap="3">
              <Text className="font-semibold">Swap</Text>
              <Text
                className="font-semibold text-grayA-9 hover:text-inherit"
                asChild
              >
                <Link href="/nft">NFTs</Link>
              </Text>
            </Flex>
          )}

          <Flex align="center" className="gap-2 text-xl">
            <IconButton
              variant="ghost"
              size="1"
              color="gray"
              disabled={isQuoteLoading}
              onClick={() => refetch?.()}
            >
              <RefreshIcon />
            </IconButton>
            <IconButton
              variant="ghost"
              size="1"
              color="gray"
              onClick={setPage(SwapStep.CUSTOM_TOKENS)}
            >
              <TokensIcon />
            </IconButton>
            <IconButton
              variant="ghost"
              size="1"
              color="gray"
              onClick={setPage(SwapStep.SETTINGS)}
            >
              <SettingsIcon />
            </IconButton>
          </Flex>
        </Flex>
        <div>
          <TokenInput isloading={isLoading} />
          <div className="-my-2 flex w-full justify-center">
            <IconButton
              className="hover:[--rotate-arrow:180deg]"
              color="gray"
              onClick={() => dispatch(swapTokenDirection())}
            >
              <ArrowDownIcon className="rotate-[var(--rotate-arrow,0)] transition-transform" />
            </IconButton>
          </div>
          <TokenInput isOutput isloading={isLoading} />
        </div>
        {quoteError && (
          <Callout.Root color="red">
            <Callout.Icon>
              <InfoCircledIcon />
            </Callout.Icon>
            <Callout.Text>Fetch failed please retry</Callout.Text>
          </Callout.Root>
        )}
        <ConfirmSwap isLoading={isLoading} />

        {isMounted && (
          <>
            {address ? (
              <>
                {quoteError ? (
                  <Button
                    className="swap-button"
                    color="red"
                    onClick={() => refetch?.()}
                  >
                    Retry
                  </Button>
                ) : isQuoteLoading || isLoading ? (
                  <Button className="swap-button" disabled>
                    <span className="flex h-6 w-1/2 animate-pulse rounded bg-gray-5"></span>
                  </Button>
                ) : (
                  <>
                    {inputAmountBn > 0n &&
                      isApproved &&
                      hasBalance &&
                      hasGasReserves && (
                        <Button
                          className="swap-button"
                          color={priceImpact > 5 ? "red" : "green"}
                          onClick={setPage(SwapStep.CONFIRM)}
                        >
                          {priceImpact > 5
                            ? "High Price Impact"
                            : "Confirm Swap"}
                        </Button>
                      )}

                    {inputAmountBn > 0n &&
                      !isApproved &&
                      hasBalance &&
                      hasGasReserves && (
                        <Button
                          className="swap-button"
                          onClick={() => write?.(params)}
                        >
                          Approve {inputToken?.symbol}
                        </Button>
                      )}

                    {inputAmountBn > 0n && !hasBalance && (
                      <Button className="swap-button" disabled>
                        Insufficient {inputToken?.symbol} Balance
                      </Button>
                    )}

                    {inputAmountBn > 0n && !hasGasReserves && hasBalance && (
                      <Button className="swap-button" disabled>
                        Insufficient gas to perform swap
                      </Button>
                    )}

                    {inputAmountBn === 0n && (
                      <Button className="swap-button" disabled>
                        Enter an amount
                      </Button>
                    )}
                  </>
                )}
              </>
            ) : (
              <Button className="swap-button" onClick={openConnectModal}>
                Connect Wallet
              </Button>
            )}
          </>
        )}
      </Flex>
    </>
  );
}
