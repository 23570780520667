import {
  SerializedTokenType,
  setInputToken,
  setOutputToken,
  setSwapStep,
  SwapStep,
  swapTokenDirection,
  useInputToken,
  useOutputToken,
  useSwapStep,
} from "@/app/features/swap/slice";
import { Address, erc20Abi, zeroAddress } from "viem";
import { useAccount, useChainId, useReadContracts } from "wagmi";
import { useAppDispatch } from "@/app/hooks";
import {
  addCustomToken,
  toggleFavouriteToken,
  useFavoriteTokens,
} from "@/app/features/swap/persistedSlice";
import React, { MouseEventHandler, useMemo } from "react";
import clsx from "clsx";
import { Avatar, Button, IconButton } from "@radix-ui/themes";
import {
  Cross2Icon,
  ExternalLinkIcon,
  StarFilledIcon,
  StarIcon,
} from "@radix-ui/react-icons";
import Link from "next/link";

interface RowProps {
  token?: SerializedTokenType | Address;
  balance?: number;
  isBalanceLoading?: boolean;
  isLoading?: boolean;
  customOnly?: boolean;
}

export default function Row({
  token: _token = zeroAddress,
  balance,
  isBalanceLoading,
  isLoading: isLoading_,
  customOnly,
}: RowProps) {
  const chainId = useChainId();
  const dispatch = useAppDispatch();
  const swapStep = useSwapStep();
  const favoriteTokens = useFavoriteTokens(chainId);
  const isInTokenList = typeof _token !== "string";
  const tokenAddress = typeof _token === "string" ? _token : zeroAddress;

  const { data: __token, isLoading: isTokenLoading } = useReadContracts({
    allowFailure: false,
    query: {
      enabled: tokenAddress && tokenAddress !== zeroAddress && !isInTokenList,
      select: ([decimals, name, symbol, totalSupply]) => ({
        decimals,
        symbol,
        name,
        totalSupply,
        address: tokenAddress,
      }),
    },
    contracts: [
      {
        address: tokenAddress,
        abi: erc20Abi,
        functionName: "decimals",
      },
      {
        address: tokenAddress,
        abi: erc20Abi,
        functionName: "name",
      },
      {
        address: tokenAddress,
        abi: erc20Abi,
        functionName: "symbol",
      },
      {
        address: tokenAddress,
        abi: erc20Abi,
        functionName: "totalSupply",
      },
    ],
  });

  const token = useMemo<SerializedTokenType | undefined>(() => {
    if (isInTokenList) {
      return _token;
    }
    if (__token) {
      return {
        address: __token.address,
        decimals: __token.decimals,
        name: __token.name,
        symbol: __token.symbol,
        logoURI: "",
        chainId,
      };
    }
    return;
  }, [_token, __token, chainId, isInTokenList]);

  const inputToken = useInputToken();
  const outputToken = useOutputToken();
  const isInputToken = useMemo(
    () => Boolean(token?.address && inputToken?.address === token.address),
    [token, inputToken],
  );
  const isOutputToken = useMemo(
    () => Boolean(token?.address && outputToken?.address === token.address),
    [token, outputToken],
  );
  const isFavorite = useMemo(
    () =>
      Boolean(token && favoriteTokens.find((t) => t.address === token.address)),
    [favoriteTokens, token],
  );

  const isSelected = useMemo(() => {
    return isInputToken || isOutputToken;
  }, [isInputToken, isOutputToken]);

  const { chain } = useAccount();
  const selectToken = () => {
    if (customOnly) {
      if (!isInTokenList) {
        dispatch(setInputToken(token));
        dispatch(setSwapStep(SwapStep.CUSTOM_INPUT_TOKEN));
      }
      return;
    }
    if (
      isSelected &&
      ((swapStep === SwapStep.SELECT_INPUT_TOKEN && isOutputToken) ||
        (swapStep === SwapStep.SELECT_OUTPUT_TOKEN && isInputToken))
    ) {
      dispatch(swapTokenDirection());
      dispatch(setSwapStep(SwapStep.SWAP));
      return;
    }

    if (!token || isSelected) return;

    const stFunc =
      swapStep === SwapStep.SELECT_INPUT_TOKEN ? setInputToken : setOutputToken;
    dispatch(stFunc(token));

    if (!isInTokenList) {
      dispatch(
        setSwapStep(
          swapStep === SwapStep.SELECT_INPUT_TOKEN
            ? SwapStep.CUSTOM_INPUT_TOKEN
            : SwapStep.CUSTOM_OUTPUT_TOKEN,
        ),
      );
    } else {
      dispatch(setSwapStep(SwapStep.SWAP));
    }
  };

  const toggleFavorite: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    if (token) {
      dispatch(toggleFavouriteToken(token));
    }
  };

  const isLoading = useMemo(
    () => isLoading_ || isTokenLoading,
    [isLoading_, isTokenLoading],
  );

  const removeFromCustom = () => {
    if (!token) return;
    dispatch(addCustomToken(token));
    if (isOutputToken) {
      dispatch(setOutputToken());
    }
    if (isInputToken) {
      dispatch(setInputToken());
    }
  };

  return (
    <div
      className={clsx("flex h-[60px] items-center justify-between bg-grayA-2", {
        "cursor-pointer": !isSelected || customOnly,
        "cursor-default opacity-50": isSelected && !customOnly,
        "hover:bg-grayA-1": token,
      })}
      role="button"
      onClick={selectToken}
    >
      <div className="flex gap-3 px-3">
        {token && !isLoading ? (
          <Avatar
            fallback={token.symbol[0] || ""}
            src={token.logoURI}
            radius="full"
          />
        ) : (
          <span className="flex h-8 w-8 animate-pulse rounded-full bg-gray-5" />
        )}
        <div className="flex max-w-[114px] flex-col sm:max-w-none">
          <div className="overflow-hidden text-ellipsis whitespace-nowrap text-sm font-bold">
            {token?.name || (
              <span className="mb-1 flex h-[15px] w-[65px] animate-pulse rounded bg-gray-5" />
            )}
          </div>
          <div className="text-xs text-grayA-11">
            {token?.symbol || (
              <span className="flex h-[12px] w-[35px] animate-pulse rounded bg-gray-5" />
            )}
          </div>
        </div>
      </div>
      <div className="flex items-center gap-4 px-3">
        <div className="text-sm font-medium">
          {token && !isBalanceLoading ? (
            balance ? (
              `$${balance.toFixed(2)}`
            ) : null
          ) : (
            <span className="flex h-[14px] w-[68px] animate-pulse rounded bg-gray-5" />
          )}
        </div>
        {token && !isLoading ? (
          isInTokenList ? (
            <>
              {customOnly ? (
                <div className="flex gap-1">
                  <IconButton
                    onClick={removeFromCustom}
                    variant={"ghost"}
                    color="gray"
                    aria-label={`Remove ${token.symbol} from custom tokens`}
                  >
                    <Cross2Icon />
                  </IconButton>
                  <IconButton
                    variant={"ghost"}
                    color="gray"
                    asChild
                    aria-label={`View ${token.symbol} on ${chain?.blockExplorers?.default.name}`}
                  >
                    <Link
                      href={`${chain?.blockExplorers?.default.url}/token/${token?.address}`}
                      target="_blank"
                      className="flex items-center gap-1 hover:underline"
                    >
                      <ExternalLinkIcon />
                    </Link>
                  </IconButton>
                </div>
              ) : (
                <IconButton
                  onClick={toggleFavorite}
                  variant={"ghost"}
                  color={isFavorite ? "yellow" : "gray"}
                  aria-label={
                    isFavorite
                      ? `Remove ${token.symbol} from favorites`
                      : `Add ${token.symbol} to favorites`
                  }
                >
                  {isFavorite ? <StarFilledIcon /> : <StarIcon />}
                </IconButton>
              )}
            </>
          ) : (
            <Button size="1">Import</Button>
          )
        ) : (
          <span className="flex h-[30px] w-[30px] animate-pulse rounded-full bg-gray-5" />
        )}
      </div>
    </div>
  );
}
